/* Ion Lists */
.md ion-list-header,
.ios ion-list-header {
	font-size: 0.875rem;
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Roboto, sans-serif;
	line-height: 1.25rem;
	word-spacing: 0.025rem;
	white-space: nowrap;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
	align-items: center;
}

.md ion-list-header > ion-label,
.ios ion-list-header > ion-label {
	margin: 0;
}

.md ion-list-header ion-label:first-of-type,
.ios ion-list-header ion-label:first-of-type {
	font-weight: bold;
}

/* Ion Item */
.Input + .Group {
	margin-top: 1rem;
}

.md ion-list ion-item,
.ios ion-list ion-item {
	--inner-padding-start: 1.5rem;
	--inner-padding-end: 0.5rem;
	--border-color: var(--ion-color-gray200);
}

.ios ion-item::part(native),
.md ion-item::part(native) {
	padding-left: unset;
}

/* Ion Label */
.md ion-label {
	font-family: sans-serif;
	color: #1c1c1c;
	font-size: 0.875rem;
	min-width: 0%;
	font-weight: 500;
}

.ios ion-label {
	font-family: sans-serif;
	color: #1c1c1c;
	font-size: 0.875rem;
	min-width: 0%;
	font-weight: 500;
}

.scroll-content {
	justify-content: center;
	display: flex !important;
	align-items: center !important;
}

.textarea-wrapper.sc-ion-textarea-md {
	font-size: 0.8rem !important;
}

.textarea-wrapper.sc-ion-textarea-ios {
	font-size: 0.8rem !important;
}

.list-header-lines-full {
	--border-color: --ion-color-gray600;
}

.item-inner {
	max-width: 100%;
	--inner-padding-end: 0rem;
}

.alert-radio-label.sc-ion-alert-md {
	white-space: normal;
}

.alert-radio-label.sc-ion-alert-ios {
	white-space: normal;
}

/* Ion Toolbar */
ion-toolbar {
	height: 48px;
}

ion-title {
	font-family: sans-serif;
	font-size: 0.75rem;
	font-weight: 500;
	position: relative;
	align-items: center;
	padding: 0;
	padding-bottom: 0;
	text-align: center;
}

/* .md ion-title {
	color: var(--ion-color-primary);
	font-family: sans-serif;
	font-size: 0.75rem;
	font-weight: 500;
	align-items: center;
	padding: 0;
}
.ios ion-title {
	color: var(--ion-color-primary);
	font-family: sans-serif;
	font-size: 0.75rem;
	font-weight: 500;
	align-items: center;
	padding-bottom: 0;
	padding-left: 0rem;
	position: "relative";
} */

/* Ion Toast */
.md ion-toast {
	margin-top: 3.5rem;
	height: calc(100% - 3.5rem);
}

.ios ion-toast {
	margin-top: 2.5rem;
	height: calc(100% - 2.5rem);
}

.md .dHubHeader {
	font-family: "Times New Roman", Times, serif;
	font-size: 1.125rem;
}

.ios .dHubHeader {
	font-family: "Times New Roman", Times, serif;
	font-size: 1.125rem;
	margin-top: 1rem;
	margin-left: 0rem;
}

.logo {
	width: 2.889rem;
	align-self: center;
}

/** Ion button **/
ion-button {
	--border-width: 1px;
	--border-radius: 4px;
	--box-shadow: 0px;
	border-radius: 4px;
}

/* Nasty stills to somewhat make texts more reasonable within form */
form ion-label,
form ion-input,
form ion-select {
	font-size: 0.8rem !important;
}

.alert-title {
	font-size: 0.85rem !important;
}

.alert-radio-label {
	font-size: 0.8rem !important;
}

/* Select/Multiselect: Fit the content of the labels inside the items.  */
.alert-tappable.sc-ion-alert-md {
	contain: none;
}

.alert-tappable.sc-ion-alert-ios {
	contain: none;
}

.alert-checkbox-label.sc-ion-alert-md {
	font-size: 0.8rem !important;
	text-overflow: clip;
	white-space: normal;
	overflow: visible;
}

.alert-checkbox-label.sc-ion-alert-ios {
	font-size: 0.8rem !important;
	text-overflow: clip;
	white-space: normal;
	overflow: visible;
}

.alert-tappable.sc-ion-alert-md {
	min-height: 3rem;
	height: auto;
}

.alert-tappable.sc-ion-alert-ios {
	min-height: 3rem;
	height: auto;
}

.capitalize-first-letter:first-letter {
	text-transform: uppercase !important;
}

ion-item,
ion-label {
	--border-color: #000000;
}

.ion-accordion-toggle-icon {
	margin: 9px 0 9px 10px;
	font-size: 18px !important;
}

/* Ion FAB */
ion-fab-button {
	--padding-start: "1.75rem";
}

/* This is to deal with a bug with this prop in ionic
For whatever reason it stacks up when moving from one input
to the next, ultimately running out of screen real state */
ion-content {
	--keyboard-offset: 0px !important;
}

/* This is an attempt to deal with 100vh problems */
body {
	min-height: 100dvh;
	margin: 0 0 0 0; /* This is an attempt to deal with default scroll on installed iOS app*/
}

html {
	overscroll-behavior: none;
}
